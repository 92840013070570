import { AccessTimeOutlined, InfoOutlined } from '@mui/icons-material';
import { Box, Button, SelectChangeEvent, Typography, useMediaQuery, useTheme } from '@mui/material';
import { LocationTypes, StoreTypes, Variants } from 'common';
import { add } from 'date-fns';
import { useFormik } from 'formik';
import React, { FC, MouseEvent, useContext, useEffect, useMemo, useState } from 'react';
import { date, number, object, string } from 'yup';

import { formatPricing } from 'application/modules/bookingWizard/common/helpers';
import useCaseChooseSessionLength, {
  SessionLengthForm,
} from 'application/modules/bookingWizard/useCases/hooks/useCaseChooseSessionLength';
import useCaseNavigateBookingWizard from 'application/modules/bookingWizard/useCases/hooks/useCaseNavigateBookingWizard';
import useCaseExternalNavigation from 'application/modules/bookingWizard/useCases/hooks/useCaseNavigateTo';
import { WizardStep } from 'domain/entities/WizardStep';
import { checkIfDateIsInAllowedRange } from 'infrastructure/components/BookingCalendar/helpers';
import ButtonsGroup from 'infrastructure/components/ButtonsGroup';
import HorizontalCard, { HorizontalCardType } from 'infrastructure/components/HorizontalCard';
import Loader from 'infrastructure/components/Loader';
import NavigationBar from 'infrastructure/components/NavigationBar';
import PageLayout from 'infrastructure/components/PageLayout';
import SectionTitle from 'infrastructure/components/SectionTitle';
import {
  selectLocation,
  selectLocationDetails,
  selectPartySize,
  selectReservationDate,
} from 'infrastructure/redux/slices/bookingWizard.selector';
import {
  setInstructor,
  setPartySize,
  setReservationDate,
} from 'infrastructure/redux/slices/bookingWizard.slice';
import { selectUserSummary } from 'infrastructure/redux/slices/user.selector';
import { useAppDispatch, useAppSelector } from 'infrastructure/redux/store/hooks';
import { TextCopyContext } from 'infrastructure/targets/web/contexts/TextCopyContext';
import ErrorBox from 'infrastructure/targets/web/modules/bookingWizard/components/ErrorBox';
import LocationSelect from 'infrastructure/targets/web/modules/bookingWizard/components/LocationSelect';
import { getReservationDateLimitDays } from 'infrastructure/targets/web/modules/bookingWizard/Steps/ReservationDateStep';
import { isAustralia, parseStaffId } from 'infrastructure/targets/web/modules/common/helpers';
import { getSimThumbnail } from 'infrastructure/targets/web/modules/common/imageUrlHelpers';

import WidgetComponent from './components/FiveIronWidget';
import PartySizeSelect from './components/PartySizeSelect';
import PricingSummary from './components/PricingSummary';
import ReservationSummary from './components/ReservationSummary';
import SessionHourSelect, { SessionHourMenuItem } from './components/SessionHourSelect';
import SmallDatePicker from './components/SmallDatePicker';
import {
  StyledAsideContainer,
  StyledButtonsContainer,
  StyledContainer,
  StyledInputContainer,
  StyledLeftContainer,
  StyledLoaderContainer,
} from './style';

interface ISessionLengthStep {
  stepName: WizardStep;
}
export interface ISessionSummary {
  session?: string;
  sessionHour?: string;
  instructor?: string;
  lessonType?: string | number;
}

const SITES_TO_SHOW_WIDGET = [-2147482202, 310101];

const SessionLengthStep: FC<ISessionLengthStep> = ({ stepName }) => {
  const { getTextCopy } = useContext(TextCopyContext);
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const selectedLocationId = useAppSelector(selectLocation);
  const user = useAppSelector(selectUserSummary);
  const selectedPartySize = useAppSelector(selectPartySize);
  const selectedLocationDetails = useAppSelector(selectLocationDetails);
  const promotions = selectedLocationDetails?.promotions?.includes('OneDollarPromo');
  const specialPromo = selectedLocationDetails?.promotions?.includes('SpecialPromo');
  // TODO: ideally we use enum throughout the code
  const isSmallEvent = selectedPartySize === '7-12' || selectedPartySize === '13+';
  const disabledHours = isSmallEvent ? [30, 60] : undefined;

  const reservationDate = useAppSelector(selectReservationDate);
  const {
    availableTimeSlots,
    prevStep,
    nextStep,
    getAppointmentPricing,
    result: appointments,
    inProgress: isPricingLoading,
    areAppointmentsLoading,
    refetchUserDataWhenLocationIdChanged,
  } = useCaseChooseSessionLength(stepName);

  const { moveToStep } = useCaseNavigateBookingWizard(stepName);
  const { navigateToContactPage } = useCaseExternalNavigation();

  const [showDayRangeError, setDayRangeError] = useState(false);

  const [sessionSummary, setSessionSummary] = useState<ISessionSummary>();
  const [selectedTimeSlot, setSelectedTimeslot] = useState<string>();
  const [selectedSessionLength, setSelectedSessionLength] = useState<string>();
  const formattedAppointments = useMemo(
    () => formatPricing(appointments).appointments,
    [appointments],
  );

  const addOn = useMemo(() => formatPricing(appointments).addOn, [appointments]);

  const showNotifyWidget = () =>
    SITES_TO_SHOW_WIDGET.includes(selectedLocationDetails?.siteId ?? 0);
  const timeslotsForMobilePicker = useMemo(
    () =>
      availableTimeSlots?.map((slot) => ({
        title: slot.sectionTitle,
        value: slot.sectionValue,
      })),
    [availableTimeSlots],
  );

  const sessionLengthsForMobilePicker = useMemo(
    () => availableTimeSlots?.find((slot) => slot.sectionValue === selectedTimeSlot)?.buttonList,
    [selectedTimeSlot],
  );

  // Triggers ReservationDate validation when PartySize changes
  useEffect(() => {
    if (selectedPartySize) {
      if (sessionLengthForm.touched.reservationDate && reservationDate) {
        handleDateChange(new Date(reservationDate));
      }
    }
  }, [selectedPartySize]);

  const validationSchema = useMemo(
    () =>
      object().shape({
        location: string()
          .required()
          .test('empty-check', 'Location cannot be empty', (fieldValue) =>
            fieldValue ? fieldValue?.length >= 0 : false,
          ),
        reservationDate: date().required(),
        partySize: string()
          .required()
          .test('empty-check', 'Party size cannot be empty', (fieldValue) =>
            fieldValue ? fieldValue?.length >= 0 : false,
          ),
        sessionLength: number().required(),
      }),
    [selectedLocationId],
  );

  const sessionLengthForm = useFormik<SessionLengthForm>({
    initialValues: {
      location: selectedLocationId || '',
      reservationDate: reservationDate && new Date(reservationDate),
      partySize: selectedPartySize ?? '1',
      startDateTime: '',
      endDateTime: '',
      staffId: null,
      sessionLength: null,
      foodOrderAvailability: StoreTypes.FoodOrderAvailability.NotAvailable,
    },
    enableReinitialize: true,
    validationSchema,
    validateOnMount: true,
    validateOnChange: true,
    onSubmit: (values) => {
      if (selectedPartySize !== '13+') {
        nextStep({
          ...values,
          foodOrderAvailability:
            selectedLocationDetails?.foodOrderAvailability ??
            StoreTypes.FoodOrderAvailability.NotAvailable,
        });
      } else {
        navigateToContactPage();
      }
    },
  });

  const RESERVATION_DATE_LIMIT_DAYS = getReservationDateLimitDays(selectedPartySize, user?.member);

  const handleSessionLengthChange = async (
    sessionLengthInMinutes: string,
    event: MouseEvent<HTMLElement>,
  ) => {
    const sessionLengthValue =
      typeof sessionLengthInMinutes !== 'number' && sessionLengthInMinutes?.includes('/')
        ? sessionLengthInMinutes?.split('/')[0]
        : sessionLengthInMinutes;

    setSelectedSessionLength(sessionLengthValue);
    const sessionHour =
      (isMobile ? selectedTimeSlot : event?.currentTarget.dataset.sectionvalue) || '';

    if (!sessionLengthValue) {
      await handleClearReservationSummary();
    } else {
      const staffIds = parseStaffId(event?.currentTarget.dataset.additionalvalue);
      dispatch(setInstructor({ instructor: isSmallEvent ? staffIds.join(',') : staffIds[0] }));
      const sessionLength = Number(sessionLengthValue) / 60;

      setSessionSummary({
        session: sessionLength.toString(),
        sessionHour,
        instructor: isSmallEvent ? staffIds.join(',') : staffIds[0].toString(),
      });

      const endDateTime = add(new Date(sessionHour), {
        minutes: Number(sessionLengthValue),
      }).toISOString();

      if (selectedLocationId && sessionHour !== endDateTime) {
        getAppointmentPricing({
          email: user?.email,
          startDateTime: sessionHour,
          endDateTime: endDateTime,
          staffIds: isSmallEvent ? staffIds : [staffIds[0]],
          locationId: selectedLocationId,
          partySize: selectedPartySize,
        });

        await sessionLengthForm.setFieldValue('startDateTime', sessionHour);
        await sessionLengthForm.setFieldValue('sessionLength', sessionLength);
        await sessionLengthForm.setFieldValue('endDateTime', endDateTime);
        await sessionLengthForm.setFieldValue('staffId', staffIds);
      }
    }
  };

  const handleDateChange = (newDate: Date | undefined) => {
    if (!newDate) return;

    if (!checkIfDateIsInAllowedRange(newDate, RESERVATION_DATE_LIMIT_DAYS)) {
      sessionLengthForm.setErrors({
        reservationDate: `Please select a date within ${RESERVATION_DATE_LIMIT_DAYS} days`,
      });
      dispatch(setReservationDate({ reservationDate: `${newDate}` }));
      sessionLengthForm.setFieldValue('reservationDate', null);
      sessionLengthForm.setTouched({ reservationDate: true });
      setDayRangeError(true);
      return;
    } else {
      setDayRangeError(false);
      setSelectedTimeslot(undefined);
      setSelectedSessionLength('unset');
      setSessionSummary({
        ...sessionSummary,
        session: undefined,
        sessionHour: '',
      });
      dispatch(setReservationDate({ reservationDate: `${newDate}` }));
      sessionLengthForm.setFieldValue('reservationDate', newDate);
      sessionLengthForm.setErrors({
        reservationDate: undefined,
      });
      sessionLengthForm.setTouched({ reservationDate: true });
    }
  };

  const handleLocationChange = (
    _: SelectChangeEvent<string>,
    value: LocationTypes.SimulatorLocation,
  ) => {
    setSelectedTimeslot(undefined);
    setSelectedSessionLength('unset');
    setSessionSummary({
      ...sessionSummary,
      session: undefined,
      sessionHour: '',
    });
    refetchUserDataWhenLocationIdChanged(value?.id);
  };

  const handlePartySizeChange = (newPartySize: string | undefined) => {
    if (newPartySize) {
      dispatch(setPartySize({ partySize: `${newPartySize}` }));
    }
    sessionLengthForm.setFieldValue('partySize', newPartySize);

    if (sessionLengthForm.values.reservationDate) {
      handleDateChange(new Date(sessionLengthForm.values.reservationDate));
    }
  };

  const handleTimeslotChange = (newTimeslot: string | undefined) => {
    setSelectedTimeslot(newTimeslot);
    setSelectedSessionLength('unset');
    sessionLengthForm.setFieldValue('sessionLength', null);
    setSessionSummary({
      ...sessionSummary,
      session: undefined,
      sessionHour: newTimeslot || '',
    });
  };

  const handleOpenPricing = () => {
    moveToStep(WizardStep.pricing);
  };

  const handleClearReservationSummary = async () => {
    setSessionSummary({
      sessionHour: undefined,
      session: undefined,
    });
    await sessionLengthForm.setFieldValue('startDateTime', null);
    await sessionLengthForm.setFieldValue('sessionLength', null);
    await sessionLengthForm.setFieldValue('endDateTime', null);
    await sessionLengthForm.setFieldValue('staffId', null);
  };

  return (
    <PageLayout pageTitle={'Start Time and Session Length'} shouldFadeIn>
      <NavigationBar backButtonCallback={prevStep} inline>
        {isMobile && (isSmallEvent || promotions || specialPromo) && (
          <Button onClick={handleOpenPricing}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <InfoOutlined sx={{ fontSize: '20px', marginRight: 2 }} />
              Pricing
            </Box>
          </Button>
        )}
      </NavigationBar>
      <StyledContainer>
        <StyledLeftContainer>
          <StyledInputContainer>
            <LocationSelect
              selectedSimLocationId={selectedLocationId}
              onChange={handleLocationChange}
            />
            <SmallDatePicker selectedDate={reservationDate} onChange={handleDateChange} />
            <PartySizeSelect
              selectedPartySize={selectedPartySize}
              onChange={handlePartySizeChange}
            />
          </StyledInputContainer>
          <Box sx={{ mb: 6 }}>
            <HorizontalCard
              variant={HorizontalCardType.Large}
              title={getTextCopy('rental')}
              content={
                <>
                  <Typography variant={'body1'}>
                    <strong>What’s included?</strong>
                  </Typography>
                  <Typography variant={'body2'}>
                    {!isAustralia() ? (
                      <>
                        {getTextCopy('rentalDescription1')}
                        <br />
                      </>
                    ) : null}
                    {getTextCopy('rentalDescription2')}
                    <br />
                    {getTextCopy('rentalDescription3')}
                    <br />
                    {getTextCopy('rentalDescription4')}
                  </Typography>
                </>
              }
              imageUrl={getSimThumbnail()}
            />
          </Box>
          <SectionTitle title={'Start Time & Session Length'} icon={<AccessTimeOutlined />} />
          {showDayRangeError ? (
            <ErrorBox partySize={selectedPartySize as string} days={RESERVATION_DATE_LIMIT_DAYS} />
          ) : (
            <StyledButtonsContainer>
              {areAppointmentsLoading && (
                <StyledLoaderContainer>
                  <Loader />
                  <Typography variant="body2">Fetching available appointments</Typography>
                </StyledLoaderContainer>
              )}
              {!areAppointmentsLoading && availableTimeSlots?.length === 0 && (
                <StyledLoaderContainer>
                  <Typography variant="body2">
                    There are no times currently available. Please choose another date.
                  </Typography>
                </StyledLoaderContainer>
              )}

              {isMobile ? (
                <>
                  {!areAppointmentsLoading && (
                    <>
                      <SessionHourSelect
                        sessionHourItems={timeslotsForMobilePicker as SessionHourMenuItem[]}
                        onChange={handleTimeslotChange}
                      />
                      {selectedTimeSlot && (
                        <ButtonsGroup
                          selectedValue={selectedSessionLength}
                          buttonList={sessionLengthsForMobilePicker}
                          onChange={handleSessionLengthChange}
                          disabledItems={disabledHours}
                          isSmallEvent={isSmallEvent}
                          hideDisabled={process.env.VITE_VARIANT === Variants.DUCKPIN}
                        />
                      )}
                    </>
                  )}
                </>
              ) : (
                !areAppointmentsLoading && (
                  <ButtonsGroup
                    buttonSections={availableTimeSlots}
                    onChange={handleSessionLengthChange}
                    disabledItems={disabledHours}
                    isSmallEvent={isSmallEvent}
                    hideDisabled={process.env.VITE_VARIANT === Variants.DUCKPIN}
                  />
                )
              )}
            </StyledButtonsContainer>
          )}
        </StyledLeftContainer>
        <StyledAsideContainer>
          <ReservationSummary
            sessionSummary={sessionSummary}
            pricing={formattedAppointments}
            showPricing={selectedSessionLength !== 'unset'}
            onSubmit={() => sessionLengthForm.handleSubmit()}
            isLoading={isPricingLoading}
            isFormValid={sessionLengthForm.isValid && selectedPartySize !== '13+'}
            isSmallEvent={isSmallEvent}
            addOns={addOn ? [addOn] : undefined}
          />
          {!isMobile && <PricingSummary />}
          {showNotifyWidget() && <WidgetComponent />}
        </StyledAsideContainer>
      </StyledContainer>
    </PageLayout>
  );
};

export default SessionLengthStep;
