import { Box, useTheme } from '@mui/material';
import React from 'react';

import { formatToCurrency } from 'infrastructure/targets/web/modules/common/helpers';

import { StyledPriceBox, StyledRateCard, StyledTitleBox } from './style';

const getFormattedPrice = (price: number) => formatToCurrency(price);
const replaceLinksWithAnchors = (text: string) => {
  const urlRegex = /(https?:\/\/\S+)/g;
  const parts = text.split(urlRegex);

  return parts.map((part, index) =>
    part.match(urlRegex) ? (
      <a
        key={index}
        href={part}
        target="_blank"
        rel="noopener noreferrer"
        style={{ color: 'inherit', textDecoration: 'none' }}>
        {part}
      </a>
    ) : (
      part
    ),
  );
};

export const ExtraFeeCard = ({
  title,
  price,
  subtitle,
}: {
  title: string;
  price?: number;
  subtitle?: string;
}) => {
  const theme = useTheme();
  const NewlineText = (text: string) =>
    text.split('\n').map((str, i) => <p key={i}>{replaceLinksWithAnchors(str)}</p>);

  return (
    <>
      <StyledRateCard
        sx={{
          marginBottom: '0',
          borderRadius: '8px',
          borderColor: theme.palette.other.outlineBorder,
        }}>
        <Box display={'flex'} flexDirection={'row'} alignItems={'center'}>
          <StyledTitleBox>
            <span style={{ fontWeight: 'bold' }}>{title}</span>
          </StyledTitleBox>
          {price ? (
            <StyledPriceBox>
              <strong>{getFormattedPrice(price)}</strong>
              &nbsp;
              <small>Per Hour</small>
            </StyledPriceBox>
          ) : null}
        </Box>
        {subtitle ? (
          <Box pl={1} pr={1} mt={2}>
            {NewlineText(subtitle)}
          </Box>
        ) : null}
      </StyledRateCard>
    </>
  );
};
