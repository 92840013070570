import { Button, CircularProgress, FormHelperText, Typography } from '@mui/material';
import { AppointmentTypes } from 'common';
import { format, formatDuration } from 'date-fns';
import { FormikProvider, useFormik } from 'formik';
import React, { FC, useContext, useEffect, useMemo, useState } from 'react';
import { object, string } from 'yup';

import useCaseConfirmReservation, {
  ConfirmReservationError,
} from 'application/modules/bookingWizard/useCases/hooks/useCaseConfirmReservation';
import { WizardStep } from 'domain/entities/WizardStep';
import ContentCard from 'infrastructure/components/ContentCard';
import HorizontalCard, { HorizontalCardType } from 'infrastructure/components/HorizontalCard';
import NavigationBar from 'infrastructure/components/NavigationBar';
import PageLayout from 'infrastructure/components/PageLayout';
import {
  selectBookingForm,
  selectClubFittingType,
  selectExperienceType,
  selectInstructorDetails,
  selectLocationDetails,
  selectResourceId,
} from 'infrastructure/redux/slices/bookingWizard.selector';
import { setTotalPrice } from 'infrastructure/redux/slices/bookingWizard.slice';
import { selectUserSummary } from 'infrastructure/redux/slices/user.selector';
import { useAppDispatch, useAppSelector } from 'infrastructure/redux/store/hooks';
import { TextCopyContext } from 'infrastructure/targets/web/contexts/TextCopyContext';
import CostSummaryContainer, {
  CostSummaryVariant,
} from 'infrastructure/targets/web/modules/bookingWizard/Steps/SessionLengthStep/components/ReservationSummary/CostSummaryContainer';
// eslint-disable-next-line max-len
import useActiveCampaignContactConversion from 'infrastructure/targets/web/common/useActiveCampaignContactConversion';
// eslint-disable-next-line max-len
import ReservationSummaryContainer from 'infrastructure/targets/web/modules/bookingWizard/Steps/SessionLengthStep/components/ReservationSummary/ReservationSummaryContainer';
import {
  CANCEL_WINDOW_HRS,
  getAdapterLocale,
  isAustralia,
} from 'infrastructure/targets/web/modules/common/helpers';
import {
  getFittingTypeImage,
  getLessonBookingImage,
  getSimThumbnail,
} from 'infrastructure/targets/web/modules/common/imageUrlHelpers';
import { useGtmEvent } from 'infrastructure/providers';

import { StyledSectionColumn } from './style';

interface IConfirmReservationStep {
  stepName: WizardStep;
}

interface IConfirmReservationForm {
  promoCode?: string;
  dailyDiscountCode?: string;
  locationId: string;
  clientServiceId?: number;
  notes: string;
  leftHanded: boolean;
  clubRental: boolean;
  startDateTime?: string;
  endDateTime?: string;
  resourceId?: number;
  appointments?: AppointmentTypes.Appointment[];
  isConfirmed?: boolean;
  totalPrice: number;
}

const ConfirmReservationStep: FC<IConfirmReservationStep> = ({ stepName }) => {
  const { getTextCopy } = useContext(TextCopyContext);
  const {
    location,
    reservationDate,
    startTime,
    sessionLength,
    endTime,
    partySize,
    addOns,
    appointments,
  } = useAppSelector(selectBookingForm);
  const isSmallEvent = partySize === '7-12';
  const user = useAppSelector(selectUserSummary);
  const locationDetails = useAppSelector(selectLocationDetails);
  const resourceId = useAppSelector(selectResourceId);
  const experienceType = useAppSelector(selectExperienceType);
  const instructorDetails = useAppSelector(selectInstructorDetails);
  const flowType = useAppSelector(selectExperienceType);
  const dispatch = useAppDispatch();
  const { sendGtmEvent } = useGtmEvent();
  const isSimFlow =
    flowType === AppointmentTypes.BookingWizardFlowTypes.Simulator ||
    flowType === AppointmentTypes.BookingWizardFlowTypes.Bowling ||
    isSmallEvent;
  const [isDisabled, setIsDisabled] = useState(false);
  const clubFittingType = useAppSelector(selectClubFittingType);

  const staffFullName = `${instructorDetails?.staffFirstName} ${instructorDetails?.staffLastName}`;
  const total = appointments?.reduce((total, item) => Number(total) + Number(item.price), 0);
  useActiveCampaignContactConversion();

  useEffect(() => {
    if (appointments && appointments.length > 0) {
      appointments.forEach((appointment, index) => {
        sendGtmEvent({
          name: `booking_${index}`,
          params: {
            book_product: String(appointment?.sessionTypeId),
            party_size: partySize ?? '',
            date_booked: appointment?.startDateTime,
            hour_picked: new Date(appointment?.startDateTime).getHours().toString(),
          },
        });
      });
    }
  }, [partySize, startTime, reservationDate, appointments?.length]);

  const calculatedAddOns: {
    name: string;
    sessionTypeId: number;
    cost?: number;
  }[] = [];
  if (isSmallEvent && addOns) {
    for (let i = 0; i < parseFloat(sessionLength ?? '0') / 0.5; ++i) {
      calculatedAddOns.push({ ...addOns[0] });
    }
  }

  const { nextStep, prevStep, inProgress, error } = useCaseConfirmReservation(stepName);
  const displayError = () => {
    switch (error) {
      case ConfirmReservationError.AppointmentUnavailable:
        return 'Sorry, but the appointment you have selected is not available anymore';
      case ConfirmReservationError.InvalidPromoCode:
        return 'Sorry, something went wrong. Please re-select the appointment and try again';
      case ConfirmReservationError.Unauthorized:
        return 'You are not logged in';
      case ConfirmReservationError.Unknown:
        return 'Unexpected error occurred, please contact our support';
      default:
        break;
    }
  };

  const summaryItems = useMemo(
    () => [
      {
        label: 'Location',
        value: locationDetails?.name,
      },
      {
        label: 'Date',
        value:
          reservationDate &&
          format(new Date(reservationDate), 'EEEE, P', {
            locale: getAdapterLocale(),
          }),
      },
      {
        label: 'Time',
        value: startTime && format(new Date(startTime.slice(0, -1)), 'hh:mm aa'),
      },
      {
        label: 'Session Length',
        value:
          sessionLength &&
          formatDuration({ hours: Number(sessionLength) }, { format: ['hours'], zero: true }),
      },
    ],
    [locationDetails, reservationDate, sessionLength],
  );

  const validationSchema = useMemo(
    () =>
      object().shape({
        promoCode: string(),
        notes: string(),
      }),
    [],
  );

  const confirmReservationForm = useFormik<IConfirmReservationForm>({
    initialValues: {
      promoCode: '',
      dailyDiscountCode: '',
      locationId: location || '',
      notes: '',
      startDateTime: startTime || '',
      endDateTime: endTime || '',
      appointments: appointments,
      leftHanded: false,
      clubRental: false,
      isConfirmed: false,
      totalPrice: total ?? 0,
    },
    enableReinitialize: true,
    validationSchema,
    validateOnChange: true,
    validateOnBlur: true,
    validateOnMount: true,
    onSubmit: (values) => {
      if (isDisabled) return;
      if (appointments && values.startDateTime && values.endDateTime) {
        setIsDisabled(true);
        dispatch(setTotalPrice(values.totalPrice));

        sendGtmEvent({
          name: 'view_cart',
          params: {
            items: appointments.map((appointment: AppointmentTypes.Appointment) => ({
              item_id: appointment?.sessionTypeId,
              item_name: appointment?.sessionTypeId.toString() ?? '',
              item_category: 'booking',
              item_brand: 'five_iron_golf',
              item_variant: appointment.resourceId?.toString() ?? '',
              price: appointment?.price,
              currency: 'USD',
              quantity: 1,
              affiliation: 'Five_Iron',
              coupon: 'five',
            })),
            currency: 'USD',
            payment_type: 'credit_card',
            value: total || 0,
          },
        });

        nextStep({
          locationId: values.locationId,
          body: {
            email: user?.email,
            promoCode: values?.isConfirmed
              ? values?.promoCode ?? ''
              : values?.dailyDiscountCode ?? '',
            partySize: isSimFlow ? parseInt(partySize ?? '0') : undefined,
            leftHanded: values?.leftHanded,
            clubRental: values?.clubRental,
            appointments: appointments.map((appointment: AppointmentTypes.Appointment) => ({
              startDateTime: appointment?.startDateTime,
              endDateTime: appointment?.endDateTime,
              staffId: appointment?.staffId,
              notes: values?.notes ?? '',
              sessionTypeId: appointment?.sessionTypeId,
              resourceId: resourceId ?? null,
              cost: Number(appointment.price),
            })),
            addOns: calculatedAddOns,
          },
        });
      }
    },
  });

  return (
    <PageLayout pageTitle={'Check Out'} shouldFadeIn>
      <NavigationBar backButtonCallback={prevStep} inline></NavigationBar>
      <StyledSectionColumn>
        <ContentCard>
          <FormikProvider value={confirmReservationForm}>
            <>
              {experienceType === AppointmentTypes.BookingWizardFlowTypes.FirstLesson && (
                <HorizontalCard
                  variant={HorizontalCardType.Small}
                  chipLabel={`${sessionLength} Hour Lesson`}
                  title={'Swing Evaluation'}
                  imageUrl={getLessonBookingImage()}
                />
              )}
              {experienceType === AppointmentTypes.BookingWizardFlowTypes.Lesson && (
                <HorizontalCard
                  variant={HorizontalCardType.Small}
                  chipLabel={instructorDetails?.staffLevel}
                  title={staffFullName || ''}
                  imageUrl={instructorDetails?.staffImage || getLessonBookingImage()}
                />
              )}
              {experienceType === AppointmentTypes.BookingWizardFlowTypes.ClubFitting && (
                <HorizontalCard
                  variant={HorizontalCardType.Large}
                  title={clubFittingType?.name ?? getTextCopy('clubFitting')}
                  content={
                    <>
                      <Typography variant={'body1'}>
                        <strong>What’s Included?</strong>
                      </Typography>
                      <Typography variant={'body2'}>
                        {getTextCopy('clubFittingDescription1')}
                      </Typography>
                    </>
                  }
                  imageUrl={getFittingTypeImage(clubFittingType?.name ?? '')}
                />
              )}
              {(experienceType === AppointmentTypes.BookingWizardFlowTypes.Simulator ||
                experienceType === AppointmentTypes.BookingWizardFlowTypes.Bowling) && (
                <HorizontalCard
                  variant={HorizontalCardType.Large}
                  title={getTextCopy('rental')}
                  content={
                    <>
                      <Typography variant={'body1'}>
                        <strong>What’s Included?</strong>
                      </Typography>
                      <Typography variant={'body2'}>
                        {!isAustralia() ? (
                          <>
                            {getTextCopy('rentalDescription1')}
                            <br />
                          </>
                        ) : null}
                        {getTextCopy('rentalDescription2')}
                        <br />
                        {getTextCopy('rentalDescription3')}
                        <br />
                        {getTextCopy('rentalDescription4')}
                      </Typography>
                    </>
                  }
                  imageUrl={getSimThumbnail()}
                />
              )}
              <Typography variant="h5">Reservation Summary</Typography>
              <ReservationSummaryContainer summaryItems={summaryItems} />
              <Typography variant="h5">Cost Summary</Typography>
              <CostSummaryContainer
                pricing={appointments}
                variant={CostSummaryVariant.SummaryWithPromoCode}
                total={total}
                addOns={addOns}
                isSmallEvent={isSmallEvent}
                sessionLength={sessionLength}
              />
              <Button
                variant="contained"
                color="primary"
                className="nextButton"
                size="large"
                onClick={() => confirmReservationForm.handleSubmit()}
                disabled={!confirmReservationForm?.isValid || !appointments || isDisabled}>
                {inProgress ? (
                  <CircularProgress sx={{ color: 'primary.contrastText' }} />
                ) : (
                  'Submit'
                )}
              </Button>
              {displayError() !== undefined && (
                <FormHelperText
                  sx={{ color: 'red', width: '100%', textAlign: 'center', mb: { xs: 5, sm: 0 } }}>
                  {displayError()}
                </FormHelperText>
              )}
              <Typography
                variant={'body1'}
                sx={{ mt: 3, mb: { xs: 10, sm: 0 }, textAlign: 'center' }}
                color={'text.secondary'}>
                You will not be charged until you arrive for your booking. Late cancellations
                (within {CANCEL_WINDOW_HRS} hours of the booking start time) will be charged 50% of
                the booking cost. No shows will be charged at 100% of the booking cost.
              </Typography>
            </>
          </FormikProvider>
        </ContentCard>
      </StyledSectionColumn>
    </PageLayout>
  );
};

export default ConfirmReservationStep;
